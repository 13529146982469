<template>
  <base-view id="contact">
    <view-header title="Contact Us" image="bg-contact.jpg">
      Hours: 8:00am - 5:00pm MST, Monday - Friday
    </view-header>

    <view-section class="bg-gray-50">
      <div class="flex flex-col justify-center lg:flex-row">
        <div
          class="px-4 py-8 mx-auto mb-8 text-center bg-white rounded-md shadow lg:mx-8 lg:mb-0 w-72"
        >
          <span class="inline-block text-6xl flaticon-location" />
          <h3 class="my-4 text-xl font-semibold">Mailing Address</h3>
          <address>
            Rocky Mountain Reserve <br />
            PO Box 631458 <br />
            Littleton CO 80163 <br />
          </address>
          <h3 class="my-4 text-xl font-semibold">COBRA Mailing Address</h3>
          <address>
            Rocky Mountain Reserve <br />
            PO Box 2440 <br />
            Omaha, NE 68103 <br />
          </address>
        </div>

        <div
          class="px-4 py-8 mx-auto mb-8 text-center bg-white rounded-md shadow lg:mx-8 lg:mb-0 w-72"
        >
          <span class="inline-block text-6xl flaticon-phone" />
          <h3 class="my-4 text-xl font-semibold">Phone</h3>

          <Button href="tel:8887221223" sml> 888.722.1223 </Button>
          <h3 class="my-4 text-xl font-semibold">Fax</h3>
          <span>866.557.0109</span>
        </div>

        <div
          class="px-4 py-8 mx-auto mb-8 text-center bg-white rounded-md shadow lg:mx-8 lg:mb-0 w-72"
        >
          <span class="inline-block text-6xl flaticon-internet" />
          <h3 class="my-4 text-xl font-semibold">Email</h3>
          <Button
            href="mailto:info@rmrbenefits.com"
            sml
            @click-button="setClipboard('info@rmrbenefits.com')"
            tooltipClick="Email Copied!"
          >
            info@rmrbenefits.com
          </Button>

          <h3 class="my-4 text-xl font-semibold">Zendesk Chat</h3>
          <!-- <Button sml @click-button="openChat"> Chat Live With Us </Button> -->
          <Button sml onclick="zE('messenger', 'open')">
            Chat Live With Us
          </Button>
        </div>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

import Button from "@/components/ui/Button.vue";

export default {
  components: {
    BaseView,
    ViewHeader,
    ViewSection,
    Button
  },
  data: function() {
    return {
      showTooltip: false
    };
  },
  methods: {
    // openChat() {
    //   var el = this;
    //   this.$zendesk.show();
    //   this.$zendesk.open();
    //   this.$zendesk.zE("webWidget:on", "close", () => {
    //     el.$zendesk.hide();
    //   });
    // },
    setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
  }
};
</script>
